import { createStore } from 'vuex'

export default createStore({
  state: {
    login: '',
    password: '',
    auth: 0,
    url: 'https://skelk.store:3212/telegram_bot/',
    async query(str) {
      const res = await fetch(str);
      const answ = (await res.json());
      return answ;
    },
    async queryPage(str) {
      const res = await fetch((`${this.url}sql?password=${this.password}&login=${this.login}&query=${encodeURIComponent(str)}`));
      const answ = (await res.json());
      return answ;
    },
    async decr(str) {
      const res = await fetch((`${this.url}decr?value=${str}`));
      const answ = (await res.json());
      console.log(answ);
      return answ;
    }
  },
  getters: {
    
  },
  mutations: {
  },
  actions: {
    
  },
  modules: {
  }
})

